import { FormEventHandler, PropsWithChildren } from "react";

import { UseFormReturn } from "react-hook-form";

import { chakra, Container, ContainerProps, StyleProps } from "@chakra-ui/react";

import FormErrorMessage from "@/components/FormErrorMessage";
import ErrorBox from "@/components/shared/ErrorBox";

const ChakraForm = chakra("form");

type FormProps = {
  errors: UseFormReturn["formState"]["errors"];
  id: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  containerProps?: ContainerProps;
} & StyleProps &
  PropsWithChildren;

export default function Form({ children, errors, id, onSubmit, containerProps, ...rest }: FormProps) {
  const rootErrors = errors.root ?? {};
  const errorKeys = Object.keys(rootErrors);

  return (
    <ChakraForm id={id} onSubmit={onSubmit} {...rest}>
      <Container ml={0} p={0} {...containerProps}>
        {errorKeys.length > 0 && (
          <ErrorBox mb={2}>
            {errorKeys.map(key => {
              return <FormErrorMessage key={key} errors={errors} name={`root.${key}`} />;
            })}
          </ErrorBox>
        )}
        {children}
      </Container>
    </ChakraForm>
  );
}
