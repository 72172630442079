import { Link } from "@chakra-ui/next-js";
import { Button, SimpleGrid } from "@chakra-ui/react";

import { FragmentType, getFragmentData, graphql } from "@/__generated__";
import { BrandedIcon } from "@/components/shared/BrandIcons";
import useReadySearchParams from "@/hooks/useReadySearchParams";
import { pathTo } from "@/utils/pathGenerators";

const SsoButtons_IdentityProvider = graphql(`
  fragment SsoButtons_IdentityProvider on IdentityProvider {
    id
    name
    authenticationUrl
  }
`);

interface Props {
  identityProviders: FragmentType<typeof SsoButtons_IdentityProvider>[];
}

export default function SsoButtons(props: Props) {
  const identityProviders = getFragmentData(SsoButtons_IdentityProvider, props.identityProviders);
  const { next } = useReadySearchParams();

  return (
    <SimpleGrid columns={1} spacing={2}>
      <SimpleGrid columns={2} spacing={2} w="full">
        {identityProviders.map(({ authenticationUrl, name }) => {
          return (
            <Button
              key={`authClient-${name}`}
              aria-label={`${name}-button`}
              as={Link}
              href={pathTo(authenticationUrl, {
                keepQuery: true,
                search: { ...(next ? { return_url: next } : {}) },
              })}
              leftIcon={<BrandedIcon brand={name} />}
              variant="outlineShadow"
              width="100%"
            >
              {name}
            </Button>
          );
        })}
      </SimpleGrid>
      <Button as={Link} href="/login/enterprise" variant="outlineShadow" w="full">
        Enterprise SSO
      </Button>
    </SimpleGrid>
  );
}
