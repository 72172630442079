import { useCallback, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { useRouter } from "next/router";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { useLocalStorage } from "usehooks-ts";
import * as yup from "yup";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Link } from "@chakra-ui/next-js";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";

import { FragmentType, getFragmentData, graphql } from "@/__generated__";
import FadeInAnimation from "@/components/FadeInAnimation";
import Form from "@/components/Form";
import FormErrorMessage from "@/components/FormErrorMessage";
import SsoButtons from "@/components/SsoButtons";
import useReadySearchParams from "@/hooks/useReadySearchParams";
import axiosClient from "@/utils/axiosClient";

const Login_IdentityProvider = graphql(`
  fragment Login_IdentityProvider on IdentityProvider {
    id
    name
    authenticationUrl
    ...SsoButtons_IdentityProvider
  }
`);

const loginSchema = yup.object({}).shape({
  email: yup
    .string()
    .email("Email must be a valid email address")
    .default(null)
    .nullable()
    .required("Valid email address required"),

  password: yup.string().default(null),
});

const emailSchema = yup.object().shape({
  email: yup.string().email("Email must be a valid email address").required("Valid email address required"),
});

/** Schemas */
type LoginValues = yup.InferType<typeof loginSchema>;

interface ApiError {
  error: string;
}

interface Props {
  identityProviders: FragmentType<typeof Login_IdentityProvider>[];
}

function Login(props: Props) {
  const identityProviders = props.identityProviders.map(identityProvider =>
    getFragmentData(Login_IdentityProvider, identityProvider)
  );
  const { next } = useReadySearchParams();
  const [lastUsedEmail, setLastUsedEmail] = useLocalStorage("lastUsedEmail", "");
  const router = useRouter();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm<LoginValues>({ resolver: yupResolver(loginSchema) });

  const [emailChecked, setEmailChecked] = useState<boolean>(false);

  const onSubmit: SubmitHandler<LoginValues> = useCallback(
    async formData => {
      const { email, password } = formData;
      try {
        const response = await axiosClient.post("/users/sign_in", {
          user: {
            email,
            password,
          },
        });
        if (response.status === 200) {
          void router.push(next || response.data.redirect_url);
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 400) {
          setError("email", { type: "custom", message: "Either your username or password is incorrect" });
          toast.error("Oops! An error has occurred.", {
            description: "Either your username or password is incorrect",
          });
        }

        if (err.response?.status === 401) {
          const error_message = (err.response.data as ApiError).error;
          setError("email", { type: "custom", message: error_message });
          toast.error("Oops! An error has occurred.", {
            description: error_message,
          });
        }
      }
    },
    [next, router, setError]
  );

  const isEnterpriseSso = async () => {
    const email = getValues("email");

    if (!email) {
      setError("email", { type: "custom", message: "Missing email address" });
      return;
    }

    if (emailSchema.isValidSync({ email })) {
      try {
        setLastUsedEmail(email);
        /**
         * returns status 200 if this user is part of an enterprise SSO enabled account
         * returns status 422 if this user is not a part of an enterprise SSO enabled account
         * returns other statuses if something unexpectedly went wrong
         */
        const response = await axiosClient.post("/auth/validate_sso_domain", { email_address: email });

        if (response.status === 200) {
          void router.push("/login/enterprise");
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 422) {
          setEmailChecked(true);
        } else {
          toast.error("Oops! An error has occurred.", {
            description: err.message,
          });
        }
      }
    } else {
      setError("email", { type: "custom", message: "Invalid email address" });
    }
  };

  useEffect(() => {
    if (!getValues("email") && lastUsedEmail) {
      setValue("email", lastUsedEmail);
    }
  }, [getValues, lastUsedEmail, setValue]);

  return (
    <Form
      containerProps={{ mb: 2 }}
      errors={errors}
      id="login"
      maxW="md"
      w="full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <VStack mb={2} spacing={2} w="full">
        <FormControl isInvalid={!!errors.email}>
          <FormLabel htmlFor="email">Email address</FormLabel>
          <InputGroup>
            <Input
              disabled={isSubmitting}
              id="email"
              placeholder="registered.email@address.com"
              type="email"
              onKeyDown={event => {
                clearErrors("email");
                if (event.key === "Enter") {
                  event.preventDefault();
                  void isEnterpriseSso();
                }
              }}
              {...register("email")}
            />
            {!emailChecked && (
              <InputRightElement width="4.5rem">
                <Button
                  aria-label="Next"
                  h="1.75rem"
                  isDisabled={!watch("email")}
                  isLoading={isSubmitting}
                  size="sm"
                  variant="outline"
                  onClick={isEnterpriseSso}
                >
                  <ArrowForwardIcon />
                </Button>
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage errors={errors} name="email" />
        </FormControl>
        <FadeInAnimation
          dataLoaded={emailChecked}
          InitialView={null}
          LoadedView={
            <FormControl isInvalid={!!errors.password} mb={2} w="full">
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup>
                <Input
                  disabled={isSubmitting}
                  id="password"
                  placeholder="Registered password"
                  type="password"
                  {...register("password")}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    aria-label="Submit"
                    h="1.75rem"
                    isDisabled={!watch("password")}
                    isLoading={isSubmitting}
                    size="sm"
                    type="submit"
                    variant="outline"
                  >
                    <ArrowForwardIcon />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormHelperText textAlign="right">
                <Link href="/reset-password">Forgot password?</Link>
              </FormHelperText>
            </FormControl>
          }
          style={{ width: "100%" }}
        />
      </VStack>
      <SsoButtons identityProviders={identityProviders} />
    </Form>
  );
}

export default Login;
