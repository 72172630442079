import { useCallback } from "react";

import { camelCase } from "lodash";
import { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { toast } from "sonner";
import { ObjectSchema } from "yup";
import { ObjectShape } from "yup/lib/object";

import { ArgumentError } from "@/__generated__/graphql";
import { camelCaseToTitleCase } from "@/utils/stringHelpers";
import { isUserError } from "@/utils/typeGuards";

export function errorMessageFormatter(message?: string): string {
  if (message) {
    const words = message.split(" ");
    const attribute = words[0];
    const titledAttribute = camelCaseToTitleCase(attribute);
    return [titledAttribute, ...words.slice(1, words.length)].join(" ");
  }
  return "";
}

type UseFormErrorHandlerProps<
  TObjectShape extends ObjectShape,
  TFieldValues extends FieldValues = FieldValues
> = {
  formSchema: ObjectSchema<TObjectShape>;
  setError: UseFormSetError<TFieldValues>;
  callback?: () => void;
};

export function useFormErrorHandler<
  TObjectShape extends ObjectShape,
  TFieldValues extends FieldValues = FieldValues
>({ formSchema, setError, callback }: UseFormErrorHandlerProps<TObjectShape, TFieldValues>) {
  return useCallback(
    (error: unknown) => {
      if (isUserError(error)) {
        const errors: Record<string, ArgumentError[]> = error.reduce((memo, error) => {
          if (error.__typename === "ArgumentError") {
            // If the argument isn't present, that means the error was on a nested attribute. Currently
            // we're returning the attribute data as snake_case.
            const attribute = error.argument || camelCase(error.attribute) || "_root";
            if (!memo[attribute]) {
              memo[attribute] = [];
            }
            memo[attribute].push(error);
          } else {
            if (!memo["_root"]) memo["_root"] = [];
            memo["_root"].push(error);
          }
          return memo;
        }, {});

        Object.keys(errors).forEach(errorAttribute => {
          const mutationErrors = errors[errorAttribute];

          const types: Record<string, string> = {};
          mutationErrors.forEach((error, i) => {
            if (error.message) {
              types[`apiError${i + 1}`] = error.message;
            }
          });

          // The attribute might exist as objectId in the form schema, but on the mutation
          // the argument is just object. For example, clusterId and cluster.
          // Determine if either exists in the formSchema
          let castedAttribute: string | undefined;
          if (formSchema.fields[errorAttribute]) {
            castedAttribute = errorAttribute;
          } else if (formSchema.fields[`${errorAttribute}Id`]) {
            castedAttribute = `${errorAttribute}Id`;
          }

          if (castedAttribute) {
            setError(
              // cast formAttribute because setError at compile time doesn't know what attributes are
              // registered with the form, but we've confirmed above that formAttribute is registered
              castedAttribute as Path<TFieldValues>,
              { types },
              { shouldFocus: true }
            );
          } else {
            setError(`root.${errorAttribute}`, { types });
          }
        });
      } else {
        const runtimeError: Error = error as Error;
        toast.error("An unexpected error occurred", {
          id: runtimeError.message,
          description: runtimeError.message,
        });
      }

      if (callback) {
        callback();
      }
    },
    [formSchema.fields, setError, callback]
  );
}
