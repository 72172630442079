import { ReactElement } from "react";

import { QueryResult } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import axios from "axios";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import Head from "next/head";

import { graphql } from "@/__generated__";
import { Workspace } from "@/__generated__/graphql";
import Login from "@/components/Login";
import LoginTwoPanelLayout from "@/layouts/LoginTwoPanelLayout";
import { camelize } from "@/utils/caseHelpers";
import { pathTo, workspacePath } from "@/utils/pathGenerators";
import { getServerSideQuery } from "@/utils/ssrQueryHelpers";

const QLoginPage = graphql(`
  query QLoginPage {
    currentUser {
      id
      isEmployee
      confirmed
      primaryEmailAddress {
        email
      }
      featureFlags {
        key
        enabled
      }
      identities {
        nodes {
          id
          identityProvider {
            id
            versionControl
          }
        }
      }
    }
    workspaces {
      nodes {
        id
        databaseId
        name
        products
      }
    }
  }
`);

const LoginPage_IdentityProvider = graphql(`
  fragment LoginPage_IdentityProvider on IdentityProvider {
    ...Login_IdentityProvider
  }
`);

function LoginPage({ identityProviders }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <>
      <Head>
        <title>Login | Release</title>
      </Head>
      <Login identityProviders={identityProviders} />
    </>
  );
}
export default LoginPage;

LoginPage.getLayout = (page: ReactElement) => {
  return <LoginTwoPanelLayout>{page}</LoginTwoPanelLayout>;
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  let currentUser, identityProviders;
  try {
    const [identityProvidersResponse, { queryData }] = await Promise.all([
      axios.get(`${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/identity_providers`),
      getServerSideQuery({ cookie: context.req.headers.cookie, query: QLoginPage }),
    ]);

    identityProviders = identityProvidersResponse;
    currentUser = (queryData as QueryResult<ResultOf<typeof QLoginPage>>).data?.currentUser;

    if (currentUser?.id) {
      const workspaces = queryData.data.workspaces.nodes;

      const defaultWorkspace =
        workspaces.find((workspace: Workspace) => workspace.name === "Release") || workspaces[0];

      if (!currentUser.confirmed) {
        return {
          redirect: {
            permanent: false,
            destination: pathTo("/register/verify", {
              req: context.req,
              search: { email: currentUser.primaryEmailAddress.email },
            }),
          },
        };
      } else if (defaultWorkspace) {
        return {
          redirect: {
            permanent: false,
            destination: workspacePath("applications", defaultWorkspace.databaseId),
          },
        };
      } else {
        return { redirect: { permanent: false, destination: "/register/connect" } };
      }
    }
  } catch (e) {
    console.log(e);
  }
  return {
    props: {
      identityProviders: camelize(identityProviders?.data ?? []) as unknown as ResultOf<
        typeof LoginPage_IdentityProvider
      >[],
    },
  };
}
