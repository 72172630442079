import { ErrorMessage } from "@hookform/error-message";
import { FieldValues, UseFormReturn, FieldPath } from "react-hook-form";

import { Text } from "@chakra-ui/react";

import { errorMessageFormatter } from "@/hooks/useFormErrorHandler";

type FormErrorMessageProps<FormValues extends FieldValues> = {
  errors: UseFormReturn["formState"]["errors"];
  name: FieldPath<FormValues>;
};

export default function FormErrorMessage<FormValues extends FieldValues>({
  errors,
  name,
}: FormErrorMessageProps<FormValues>) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message, messages }) => {
        if (message) {
          return <Text color="red">{errorMessageFormatter(message)}</Text>;
        } else if (messages) {
          return Object.entries(messages).map(([type, message]) => {
            if (typeof message === "string") {
              return (
                <Text key={type} color="red">
                  {errorMessageFormatter(message)}
                </Text>
              );
            } else {
              return null;
            }
          });
        } else {
          return null;
        }
      }}
    />
  );
}
