import { PropsWithChildren, ReactNode } from "react";

import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";

export type Props = {
  icon?: ReactNode;
  header?: string;
} & BoxProps;

export default function BoxWithIcon({ icon, header, children, ...boxProps }: PropsWithChildren<Props>) {
  return (
    <Box
      borderColor="border.base"
      borderRadius="lg"
      borderWidth="1px"
      color="text.darker"
      p={4}
      {...boxProps}
    >
      <HStack justifyContent="space-between" w="full">
        <Text color="text.lighter" mb={2}>
          {header}
        </Text>
        {icon}
      </HStack>
      {children}
    </Box>
  );
}
